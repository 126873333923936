import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'AZES';
  deferredPrompt: any;

  ngOnInit(): void {
    window.addEventListener('beforeinstallprompt', (e: any) => {
      e.preventDefault();
      this.deferredPrompt = e;
    });
  }

  showInstallPrompt() {
    console.log(this.deferredPrompt);
    this.deferredPrompt.prompt();
  }
}
