import { Component, Input, SimpleChanges } from '@angular/core';
import { TimerService } from 'src/app/services/timer.service';

@Component({
  selector: 'app-timer',
  templateUrl: './timer.component.html',
  styleUrls: ['./timer.component.scss'],
})
export class TimerComponent {
  @Input() mode: 'compact' | 'full' = 'full';

  constructor(public timerService: TimerService) {
    if (this.mode === 'full') {
      document.body.style.overflow = 'hidden';
    }
  }

  openTimerModal() {
    const w = 200;
    const h = 150;

    // Fixes dual-screen position                             Most browsers      Firefox
    const dualScreenLeft =
      window.screenLeft !== undefined ? window.screenLeft : window.screenX;
    const dualScreenTop =
      window.screenTop !== undefined ? window.screenTop : window.screenY;

    const width = window.innerWidth
      ? window.innerWidth
      : document.documentElement.clientWidth
      ? document.documentElement.clientWidth
      : screen.width;
    const height = window.innerHeight
      ? window.innerHeight
      : document.documentElement.clientHeight
      ? document.documentElement.clientHeight
      : screen.height;

    const left = (width - w) / 2 + dualScreenLeft;
    const top = (height - h) / 2 + dualScreenTop;

    window.name = 'parent';
    window.open(
      '/timer',
      'AZESTimer',
      `
      popup=1,
      name=AZESTimer,
      width=${w},
      height=${h},
      top=${top},
      left=${left}
      `
    );
  }
}
