import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { MiscService } from './misc.service';
import { UserService } from './user.service';
import {
  EMPTY,
  takeWhile,
  catchError,
  lastValueFrom,
  timer,
  Subscription,
} from 'rxjs';
import { WebsocketService } from './websocket.service';

@Injectable({
  providedIn: 'root',
})
export class TimerService {
  workTime = 0;
  breakTime = 0;

  status: 'start' | 'break' | 'stop' = 'stop';

  //timer
  workTimerSubscription?: Subscription;
  breakTimerSubscription?: Subscription;
  workTimer$ = timer(0, 1000);
  breakTimer$ = timer(0, 1000);

  private timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  private timezoneOffset = (new Date().getTimezoneOffset() / 60) * -1;

  constructor(
    private api: ApiService,
    private userService: UserService,
    private misc: MiscService,
    private webSocket: WebsocketService
  ) {
    if (userService.user?.id) this.initLastState();
  }

  /**
   * Retrieves the last state of the timer.
   */
  async initLastState() {
    this.api.getTimerState().subscribe((res) => {
      console.log(res);
      if (res.data?.type == 'start') {
        this.start(new Date(res.data?.timestamp));
      } else if (res.data?.type == 'break') {
        this.pause(new Date(res.data?.timestamp));
      }
    });
  }

  /**
   * Resets timer locally.
   */
  resetTimer() {
    this.workTimerSubscription?.unsubscribe();
    this.breakTimerSubscription?.unsubscribe();
    this.breakTime = 0;
    this.workTime = 0;
    this.status = 'stop';
  }

  /**
   * Starts the visual timer and commits the timestamp to the backend.
   * @returns void
   */
  async start(_now: Date | null = null) {
    if (!this.misc.isAppOnline) return; //Failsafe

    if (!_now) {
      _now = new Date();

      //Commit to API
      const _res = await lastValueFrom(
        this.api
          .setTimer(
            'start',
            this.userService.user.id!,
            _now.toISOString(),
            this.timezone,
            this.timezoneOffset
          )
          .pipe(catchError(() => EMPTY)),
        { defaultValue: null }
      );

      //Stop here, if there's an error
      if (_res?.success == null || !_res.success) {
        this.misc.toast(
          _res?.reason ?? 'Während des Vorgangs ist ein Fehler aufgetreten.'
        );
        return;
      }
    }

    this.status = 'start';
    const _time = this.workTime > 0 ? this.workTime : 0;

    //Start timer
    console.log('Timer started', _now.toLocaleString(), _time);
    this.workTimerSubscription = this.workTimer$
      .pipe(takeWhile((val) => this.status === 'start'))
      .subscribe((res) => {
        this.workTime =
          _time + Math.round((Date.now() - _now!.getTime()) / 1000);
        console.log('Timer', this.workTime, this.breakTime);
      });
    var audio = new Audio('../../assets/audio/start.mp3');
    audio.play();
    //this.webSocket.connection?.send(JSON.stringify({ action: 'start' })); TODO
  }

  /**
   * Pauses the visual timer and commits the timestamp to the backend.
   * @returns void
   */
  async pause(_now: Date | null = null) {
    if (!this.misc.isAppOnline) return; //Failsafe

    if (!_now) {
      _now = new Date();

      const _res = await lastValueFrom(
        this.api
          .setTimer(
            'break',
            this.userService.user.id!,
            _now.toISOString(),
            this.timezone,
            this.timezoneOffset
          )
          .pipe(catchError(() => EMPTY)),
        { defaultValue: null }
      );

      //Stop here, if there's an error
      if (_res?.success == null || !_res.success) {
        this.misc.toast(
          _res?.reason ?? 'Während des Vorgangs ist ein Fehler aufgetreten.'
        );
        return;
      }
    }

    this.status = 'break';
    const _time = this.breakTime > 0 ? this.breakTime : 0;

    //Start timer
    console.log('Timer paused', _now.toLocaleString());
    this.breakTimerSubscription = this.breakTimer$
      .pipe(takeWhile((val) => this.status === 'break'))
      .subscribe((res) => {
        this.breakTime =
          _time + Math.round((Date.now() - _now!.getTime()) / 1000);
        console.log('Timer', this.workTime, this.breakTime);
      });
    var audio = new Audio('../../assets/audio/break.mp3');
    audio.play();
    //this.webSocket.connection?.send(JSON.stringify({ action: 'pause' })); TODO
  }

  /**
   * Stops the visual timer and commits the timestamp to the backend.
   * @returns void
   */
  async stop() {
    if (!this.misc.isAppOnline) return; //Failsafe

    const _now = new Date();

    const _res = await lastValueFrom(
      this.api
        .setTimer(
          'stop',
          this.userService.user.id!,
          _now.toISOString(),
          this.timezone,
          this.timezoneOffset
        )
        .pipe(catchError(() => EMPTY)),
      { defaultValue: null }
    );

    //Stop here, if there's an error
    if (_res?.success == null || !_res.success) {
      this.misc.toast(
        _res?.reason ?? 'Während des Vorgangs ist ein Fehler aufgetreten.'
      );
      return;
    }

    this.status = 'stop';
    this.workTime = 0;
    this.breakTime = 0;
    console.log('Timer stopped', _now.toLocaleString());
    var audio = new Audio('../../assets/audio/stop.mp3');
    audio.play();
    //this.webSocket.connection?.send(JSON.stringify({ action: 'stop' })); TODO
  }
}
