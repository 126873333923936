import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class MiscService
{

  constructor(
    private _snackBar: MatSnackBar
  ) { }

  get isAppOnline()
  {
    return navigator.onLine;
  }

  toast(message: string, buttonText: string = "")
  {
    this._snackBar.open(message, buttonText, {
      horizontalPosition: "end",
      verticalPosition: "bottom",
      duration: 5000,
      panelClass: "azes-toast"
    });
  }
}
