import { Component } from '@angular/core';
import { UntypedFormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { EMPTY, switchMap } from 'rxjs';
import { User } from 'src/app/models/user';
import { ApiService } from 'src/app/services/api.service';
import { MiscService } from 'src/app/services/misc.service';
import { TimerService } from 'src/app/services/timer.service';
import { UserService } from 'src/app/services/user.service';
import { WebsocketService } from 'src/app/services/websocket.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  loginForm: UntypedFormGroup;

  constructor(
    private fb: FormBuilder,
    private api: ApiService,
    private router: Router,
    private misc: MiscService,
    private jwt: JwtHelperService,
    private timerService: TimerService,
    private userService: UserService,
    private webSocketService: WebsocketService
  ) {
    this.loginForm = fb.group({
      email: ['', [Validators.email, Validators.required]],
      password: ['', Validators.required],
    });
  }

  login() {
    if (this.loginForm.invalid) return;

    this.api
      .loginUser(
        this.loginForm.get('email')?.value,
        this.loginForm.get('password')?.value
      )
      .pipe(
        switchMap((res) => {
          if (res.data?.token) {
            const _uid = this.jwt.decodeToken(res.data.token)['uid'];
            localStorage.setItem('access_token', res.data.token);

            //this.webSocketService.connect();  //TODO: sync

            return this.api.getUser(_uid);
          } else {
            return EMPTY;
          }
        })
      )
      .subscribe({
        next: async (res) => {
          if (res.success) {
            this.userService.user = new User(res.data);
            const _user = { ...res.data };
            delete _user.avatar;

            localStorage.setItem('userData', JSON.stringify(_user));

            await this.timerService.initLastState();
            await this.router.navigate(['/private', 'dashboard']);
          } else {
            this.misc.toast(
              'Während des Vorgangs ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.'
            );
          }
        },
        error: (error) => {
          this.misc.toast(
            'Während des Vorgangs ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.'
          );
        },
      });
  }
}
