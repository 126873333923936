<app-public-container title="Login">
    <form class="content no-scroll" [formGroup]="loginForm">
        <mat-form-field floatLabel="always" appearance="outline">
            <mat-label>E-Mail</mat-label>
            <input matInput formControlName="email">
        </mat-form-field>

        <mat-form-field floatLabel="always" appearance="outline">
            <mat-label>Passwort</mat-label>
            <input #pwd type="password" matInput formControlName="password">
            <button type="button" [tabIndex]="-1" matSuffix mat-icon-button (click)="pwd.type = (pwd.type == 'password') ? 'text' : 'password'">
                <mat-icon>{{(pwd.type == 'password') ? 'visibility' : 'visibility_off'}}</mat-icon>
            </button>
        </mat-form-field>

        <button mat-flat-button color="primary" [disabled]="loginForm.invalid" (click)="login()">Einloggen</button>
    </form>

    <button mat-button routerLink="/forgot-password">Passwort vergessen?</button>
</app-public-container>
