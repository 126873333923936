<app-public-container title="Passwort vergessen" [showBackButton]="true">
    <div class="content no-scroll">

        <p style="margin-bottom:20px;">Bitte geben Sie Ihre E-Mail-Adresse ein, damit wir ihnen einen Link zum Zurücksetzen schicken können.</p>

        <mat-form-field floatLabel="always" appearance="outline">
            <mat-label>E-Mail</mat-label>
            <input matInput [formControl]="emailCtrl">
        </mat-form-field>

        <button mat-flat-button color="primary" [disabled]="emailCtrl.invalid" (click)="sendForgotEmail()">Absenden</button>
    </div>
</app-public-container>
