import { Injectable } from '@angular/core';
import { User } from '../models/user';

@Injectable({
  providedIn: 'root'
})
export class UserService
{
  user: User = new User();

  constructor()
  {
    if (localStorage.getItem("userData") != null)
    {
      this.user = new User(JSON.parse(localStorage.getItem("userData")!));
      console.log(this.user);
    }
  }
}
