import { Component } from '@angular/core';
import { FormControl, UntypedFormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { MiscService } from 'src/app/services/misc.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['../login/login.component.scss']
})
export class RegisterComponent
{
  token?: string;
  passwordCtrl: UntypedFormControl = new FormControl("", Validators.required);

  constructor(
    private api: ApiService,
    private router: Router,
    private route: ActivatedRoute,
    private misc: MiscService
  ) { }

  async ngOnInit()
  {
    const _params = await firstValueFrom(this.route.queryParamMap);

    if (_params.has("token"))
    {
      this.token = _params.get("token")!;
    } else
    {
      this.router.navigateByUrl("/");
    }
  }

  setUserPassword()
  {
    if (this.passwordCtrl.invalid || !this.token) return;

    this.api.activateUser(this.token, this.passwordCtrl.value).subscribe({
      next: (res) =>
      {
        if (res.success)
        {
          this.passwordCtrl.reset();
          this.misc.toast("Du wurdest erfolgreich registriert und wirst in Kürze weitergeleitet.");
          setTimeout(() =>
          {
            this.router.navigateByUrl("/login");
          }, 3000);
        } else
        {
          this.misc.toast("Während der Registrierung ist ein Fehler aufgetreten.");
        }
      },
      error: (error) =>
      {
        this.misc.toast("Während der Registrierung ist ein Fehler aufgetreten.");

      },
    });
  }
}
