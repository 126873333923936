import { Pipe, PipeTransform } from '@angular/core';

type OutputMode = 'full' | 'noMinutes' | 'noSeconds';

@Pipe({
  name: 'timer'
})
export class TimerPipe implements PipeTransform
{


  transform(value: number, mode: OutputMode = "full"): string
  {
    return this.getTimeString(value, mode);
  }

  getTimeString(time: number, mode: OutputMode): string
  {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = time % 60;

    switch (mode)
    {
      case "noSeconds": return this.formatTime(hours) + ':' + this.formatTime(minutes);
      case "noMinutes": return this.formatTime(hours);
      case "full":
      default: return this.formatTime(hours) + ':' + this.formatTime(minutes) + ':' + this.formatTime(seconds);
    }
  }

  private formatTime(time: number): string
  {
    return time < 10 ? '0' + time : time.toString();
  }

}
