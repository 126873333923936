import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Absence } from '../models/absence';
import { ApiResult } from '../models/api-result';
import { AuthToken } from '../models/auth';
import { ContractHour } from '../models/contractHour';
import { CompanyHoliday, CustomHoliday, HolidayAPI } from '../models/holiday';
import { Record, RecordDetail } from '../models/record';
import { User } from '../models/user';
import { Statistic } from '../models/statistic';

@Injectable({
  providedIn: 'root',
})
export class ApiService
{
  constructor(private http: HttpClient) { }

  ///////////////////////////////////////////////////////AUTH

  /**
   * Signs the user into the system and grants access to further API routes.
   * @param email
   * @param password
   * @returns Auth access token.
   */
  loginUser(email: string, password: string)
  {
    return this.http.post<ApiResult<AuthToken>>(
      `${environment.apiUrl}/auth/login`,
      { email, password }
    );
  }

  ///////////////////////////////////////////////////////USERS

  /**
   * Sends a command to the email system to resend an activation mail to given user.
   * @param userId
   * @returns Result of the process.
   */
  resendActivationMail(userId: number)
  {
    return this.http.post<ApiResult>(
      `${environment.apiUrl}/users/${userId}/resendActivationMail`,
      {}
    );
  }

  /**
   * Deactivates the user with the given user id.
   * @param userId
   * @returns Result of the process.
   */
  deactivateUser(userId: number)
  {
    return this.http.patch<ApiResult>(
      `${environment.apiUrl}/users/${userId}/deactivate`,
      {}
    );
  }

  /**
   * Activates the user with the given user id.
   * @param userId
   * @returns Result of the process.
   */
  activateUser(token: string, password: string)
  {
    return this.http.patch<ApiResult>(`${environment.apiUrl}/users/activate`, {
      token,
      password,
    });
  }

  /**
   * Returns all registered users. Admin only.
   * @returns An array containing user objects.
   */
  getUsers()
  {
    return this.http.get<ApiResult<User[]>>(`${environment.apiUrl}/users`);
  }

  /**
   * Searches a user by id and returns its object.
   * @param userId
   * @returns A single user object.
   */
  getUser(userId: number)
  {
    return this.http.get<ApiResult<User>>(
      `${environment.apiUrl}/users/${userId}`
    );
  }

  /**
   * Registers a new user into the system. Admin only.
   * @param user
   * @returns Result of the process.
   */
  createUser(general: User, contract: ContractHour)
  {
    return this.http.post<ApiResult>(`${environment.apiUrl}/users`, {
      general,
      contract,
    });
  }

  /**
   * Updates the avatar of the given user.
   * @param userId
   * @param avatar
   * @returns Result of the process.
   */
  editUserAvatar(userId: number, avatar: string)
  {
    return this.http.patch<ApiResult>(
      `${environment.apiUrl}/users/${userId}/avatar`,
      { avatar }
    );
  }

  /**
   * Updates the password of the given user.
   * @param userId
   * @param passwordOld
   * @param passwordNew
   * @returns Result of the process.
   */
  editUserPassword(
    userId: number,
    data: { passwordOld: string; passwordNew: string; }
  )
  {
    return this.http.patch<ApiResult>(
      `${environment.apiUrl}/users/${userId}/password`,
      data
    );
  }

  /**
   * Updates the general data of the given user.
   * @param userId
   * @param firstName
   * @param lastName
   * @param email
   * @returns Result of the process.
   */
  editUser(
    userId: number,
    data: { firstName: string; lastName: string; email: string; }
  )
  {
    return this.http.patch<ApiResult>(
      `${environment.apiUrl}/users/${userId}`,
      data
    );
  }

  //CONTRACTS

  /**
   * Inserts new contract hours (Vereinbarte Wochenarbeitsstunden) to the given user.
   * @param userId
   * @param contractHour
   * @returns Result of the process.
   */
  createContractHours(userId: number, contractHour: ContractHour)
  {
    return this.http.post<ApiResult>(
      `${environment.apiUrl}/contracts/${userId}`,
      contractHour
    );
  }

  /**
   * Returns all contract hours (Vereinbarte Wochenarbeitsstunden) of the given user.
   * @param userId
   * @returns An array containing contract hour objects.
   */
  getContractHours(userId: number)
  {
    return this.http.get<ApiResult<ContractHour[]>>(
      `${environment.apiUrl}/contracts/${userId}`
    );
  }

  /**
   * Returns one contract hour (Vereinbarte Wochenarbeitsstunden) of the given user.
   * @param userId
   * @returns An array containing contract hour objects.
   */
  getValidContractHour(userId: number)
  {
    return this.http.get<ApiResult<ContractHour>>(
      `${environment.apiUrl}/contracts/${userId}/valid`
    );
  }

  /**
   * Removes a given contract hour (Vereinbarte Wochenarbeitsstunde) from the given user.
   * @param userId
   * @param id
   * @returns Result of the process.
   */
  deleteContractHours(id: number)
  {
    return this.http.delete<ApiResult>(`${environment.apiUrl}/contracts/${id}`);
  }

  /**
   * Inserts new absences (Abwesenheiten) to the given user.
   * @param userId
   * @param data
   * @returns Result of the process.
   */
  createAbsence(userId: number, data: Absence)
  {
    return this.http.post<ApiResult>(
      `${environment.apiUrl}/absences/${userId}`,
      data
    );
  }

  /**
   * Returns all absences (Abwesenheiten) of the given user.
   * @param userId
   * @returns An array with absence objects.
   */
  getAbsences(userId: number, reason?: Absence['reason'])
  {
    return this.http.get<ApiResult<Absence[]>>(
      `${environment.apiUrl}/absences/${userId}`,
      {
        params: {
          filter: reason ?? [],
        },
      }
    );
  }

  /**
   * Removes a given absence (Abwesenheit) from the given user.
   * @param userId
   * @param id
   * @returns Result of the process.
   */
  deleteAbsence(id: number)
  {
    return this.http.delete<ApiResult>(`${environment.apiUrl}/absences/${id}`);
  }

  ///////////////////////////////////////////////////////RECORDS

  /**
   * Retrieves statistics about current user
   */
  getUserStatistics(userId: number)
  {
    return this.http.get<ApiResult<Statistic>>(
      `${environment.apiUrl}/records/${userId}/statistic`
    );
  }

  /**
   * Downloads the complete records table as xlsx file.
   */
  downloadRecordsXLSX()
  {
    return this.http.get<any>(`${environment.apiUrl}/records/export`, {
      responseType: 'blob' as 'json',
    });
  }

  /**
   * Returns all records (Arbeitszeiten) for the given user.
   * @param userId
   * @param start
   * @param end
   * @returns An array containing all record objects.
   */
  getRecordsByUserId(userId: number, start?: string, end?: string)
  {
    let params = new HttpParams();
    if (start) params = params.set('start', start);
    if (end) params = params.set('end', end);

    return this.http.get<ApiResult<Record[]>>(
      `${environment.apiUrl}/records/users/${userId}`,
      { params }
    );
  }

  /**
   * Returns a record detail (Arbeitsstunden) of a single record (Arbeitszeit).
   * @param id
   * @param original
   * @returns An array containing record details of the given record.
   */
  getRecordDetailsByDay(userId: number, day: string)
  {
    return this.http.get<ApiResult<RecordDetail[]>>(
      `${environment.apiUrl}/records/users/${userId}/days/${day}`
    );
  }

  /**
   * Inserts a new record (Arbeitszeit) for the given user. Admin only.
   * @param userId
   * @param data
   * @returns Result of the process.
   */
  createRecord(userId: number, data: RecordDetail)
  {
    return this.http.post<ApiResult>(
      `${environment.apiUrl}/records/${userId}`,
      data
    );
  }

  /**
   * Removes a given record from the user. Admin only.
   * @param id
   * @returns Result of the process.
   */
  deleteRecord(id: number)
  {
    return this.http.delete<ApiResult>(`${environment.apiUrl}/records/${id}`);
  }

  //OFFICIAL HOLIDAYS

  /**
   * Returns all official holidays for NRW of the current year.
   * @returns A holiday object
   */
  getOfficialHolidays(land: string)
  {
    const _currentYear = new Date().getFullYear();
    return this.http.get<HolidayAPI>(
      `https://get.api-feiertage.de?years=${_currentYear}&states=${land}`
    );
  }

  setFederalState(state: string)
  {
    return this.http.patch<ApiResult>(`${environment.apiUrl}/tenants/state`, {
      state,
    });
  }

  getFederalState()
  {
    return this.http.get<ApiResult<{ state: string; }>>(
      `${environment.apiUrl}/tenants/state`
    );
  }

  /**
   * Returns all custom created holidays.
   * @returns An array containing all holiday objects.
   */
  getCustomHolidays()
  {
    return this.http.get<ApiResult<CustomHoliday[]>>(
      `${environment.apiUrl}/holidays/custom`
    );
  }

  createCustomHoliday(data: CustomHoliday)
  {
    return this.http.post<ApiResult>(
      `${environment.apiUrl}/holidays/custom`,
      data
    );
  }


  deleteCustomHoliday(id: number)
  {
    return this.http.delete<ApiResult>(
      `${environment.apiUrl}/holidays/custom/${id}`
    );
  }

  //COMPANY OUTINGS
  getCompanyOutings()
  {
    return this.http.get<ApiResult<CustomHoliday[]>>(
      `${environment.apiUrl}/holidays/outings`
    );
  }

  createCompanOuting(data: CustomHoliday)
  {
    return this.http.post<ApiResult>(
      `${environment.apiUrl}/holidays/outings`,
      data
    );
  }

  deleteCompanOuting(id: number)
  {
    return this.http.delete<ApiResult>(
      `${environment.apiUrl}/holidays/outings/${id}`
    );
  }

  //COMPANY HOLIDAYS
  getCompanyHolidays()
  {
    return this.http.get<ApiResult<CompanyHoliday[]>>(
      `${environment.apiUrl}/holidays/company`
    );
  }

  /**
   * Inserts a new holiday into the system. Admin only.
   * @param data
   * @returns Result of the process.
   */
  createCompanyHoliday(data: CompanyHoliday)
  {
    return this.http.post<ApiResult>(
      `${environment.apiUrl}/holidays/company`,
      data
    );
  }

  /**
   * Removes a holiday by given id.
   * @param id
   * @returns Result of the process.
   */
  deleteCompanyHoliday(id: number)
  {
    return this.http.delete<ApiResult>(
      `${environment.apiUrl}/holidays/company/${id}`
    );
  }

  //TIMER

  /**
   * Retrieves the last timer state from the backend.
   * @returns The last timer state ("work" | "break" | "stop").
   */
  getTimerState()
  {
    return this.http.get<ApiResult<RecordDetail>>(
      `${environment.apiUrl}/records/timer`
    );
  }

  /**
   * Sends timer start date to the backend.
   * @param mode
   * @param userId
   * @param timestamp
   * @returns Result of the process.
   */
  setTimer(
    mode: 'start' | 'break' | 'stop',
    userId: number,
    timestamp: string,
    timezone: string,
    timezoneOffset: number
  )
  {
    return this.http.post<ApiResult>(
      `${environment.apiUrl}/records/timer/${mode}`,
      { timestamp, timezone, timezoneOffset }
    );
  }
}
