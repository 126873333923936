import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-public-container',
  templateUrl: './public-container.component.html',
  styleUrls: ['./public-container.component.scss']
})
export class PublicContainerComponent
{
  @Input() title: string = "";
  @Input() showLogo: boolean = true;
  @Input() showFooter: boolean = true;
  @Input() showBackButton: boolean = false;
}
