import { Component } from '@angular/core';
import { FormControl, UntypedFormControl, Validators } from '@angular/forms';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['../login/login.component.scss']
})
export class ForgotPasswordComponent
{
  emailCtrl: UntypedFormControl = new FormControl("", [Validators.required, Validators.email]);

  constructor(
    private api: ApiService
  ) { }

  sendForgotEmail()
  {
    if (this.emailCtrl.invalid) return;
    console.log(this.emailCtrl.value);

    //TODO: API Route
  }
}
