<app-public-container title="Registrierung - Passwort setzen">
    <div class="content no-scroll">

        <p style="margin-bottom:20px;">Geben Sie nun Ihr Wunsch-Passwort ein. Bitte überprüfen Sie die Eingabe vor dem Absenden!</p>

        <mat-form-field floatLabel="always" appearance="outline">
            <mat-label>Passwort</mat-label>
            <input #pwd type="password" matInput [formControl]="passwordCtrl" pattern="(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}">
            <button matSuffix mat-icon-button (click)="pwd.type = (pwd.type == 'password') ? 'text' : 'password'">
                <mat-icon>{{(pwd.type == 'password') ? 'visibility' : 'visibility_off'}}</mat-icon>
            </button>
            <mat-error>Mindestens 8 Zeichen, ein Großbuchstabe, ein Kleinbuchstabe und eine Zahl.</mat-error>
        </mat-form-field>

        <button style="margin-top:20px;" mat-flat-button color="primary" (click)="setUserPassword()">Passwort
            setzen</button>
    </div>
</app-public-container>
