export enum Role
{
    User = 'user',
    Admin = 'admin'
}

export interface UserDTO
{
    id?: number;
    activated?: string;
    avatar?: string;
    role: Role;
    firstName: string;
    lastName: string;
    email: string;
}

export class User implements UserDTO
{
    id?: number;
    role: Role = Role.User;
    firstName: string = "";
    lastName: string = "";
    email: string = "";
    avatar: string = "";
    activated?: string;

    //internal
    workHoursDifference?: number;

    constructor(data?: UserDTO)
    {
        if (data)
        {
            this.id = data.id;
            this.avatar = data.avatar ?? '';
            this.role = data.role;
            this.firstName = data.firstName;
            this.lastName = data.lastName;
            this.email = data.email;
            this.activated = data.activated;
        }
    }

    /**
     * True, if the current user is an admin, otherwise false.
     * @returns boolean
     */
    public get isAdmin()
    {
        return this.role === Role.Admin;
    }
}
